<template>
  <div class="">
    <no-results v-if="!quotations.length" />
    <rfqs-show-list-item
      v-for="quotation in quotations"
      :quotation="quotation"
      :key="quotation.id"
    />
  </div>
</template>

<script>
import NoResults from "./NoResults.vue";
import RfqsShowListItem from "./RfqsShowListItem.vue";
export default {
  components: { RfqsShowListItem, NoResults },
  props: {
    quotations: {
      type: Array,
    },
  },
};
</script>

<style></style>
