<template>
  <article class="md:flex shadow-sm bg-white mb-6 w-full">
    <section class="md:w-1/3 lg:w-1/4 flex flex-col p-4">
      <div class="w-auto flex-shrink-0 h-60 border border-gray-200 rounded">
        <img
          :src="
            quotation.product.images.length
              ? featuredImage
              : 'https://via.placeholder.com/500'
          "
          alt=""
          class="w-full h-full object-center object-cover"
        />
      </div>
      <!-- <div class="flex overflow-x-auto h-20"> -->
      <div
        class="flex overflow-x-auto space-x-2 mt-2"
        v-if="quotation.product.images.length"
      >
        <img
          v-for="(image, index) in quotation.product.images"
          :key="image.name"
          :src="image.original"
          class="h-20 w-20"
          @click="featuredImageIndex = index"
        />
      </div>
    </section>
    <section class="flex-1 sm:flex">
      <section class="flex-1 p-4">
        <header class="mb-4 pb-2 border-b-2">
          <h3 class="text-lg font-medium leading-6 text-gray-900">
            {{ quotation.name }}
          </h3>

          <p class="font-normal text-base text-gray-500">
            Vanzator {{ quotation.vendor.name }}
          </p>
        </header>
        <h4 class="text-base">Masina</h4>
        <ul class="list-disc list-inside text-sm text-gray-500">
          <li>
            Marca:
            <span class="ml-1">{{ quotation.product.car.make.name }}</span>
          </li>
          <li>
            Model:
            <span class="ml-1">{{ quotation.product.car.name }}</span>
          </li>
        </ul>

        <h4 class="text-base mt-4">Aditionale</h4>
        <ul class="flex flex-row space-x-6 text-sm text-gray-500">
          <li>
            Garantie:
            <span class="ml-1">{{ quotation.warranty }} zile </span>
          </li>
          <li>
            Posibilitate de retur:
            <span class="ml-1"> {{ quotation.return }} zile </span>
          </li>
        </ul>
      </section>

      <footer class="p-4">
        <p class="text-xl font-extrabold text-gray-900 mb-2">
          {{ formatPrice(quotation.price) }}
        </p>

        <p class="text-sm font-normal text-gray-500 mb-4">
          In stock: <span class="ml-1 text-gray-700"> {{ quotation.product.stock }} </span> buc.
        </p>

        <add-to-cart-button
          :product="quotation.product"
          :extra="{
            quotation_id: quotation.id,
          }"
        />
      </footer>
    </section>
  </article>
</template>

<script>
import AddToCartButton from "./AddToCartButton.vue";
import { priceFormatter } from "@/helpers";

export default {
  components: { AddToCartButton },
  props: {
    quotation: Object,
  },
  emits: ["addToCart:item"],

  data() {
    return {
      featuredImageIndex: 0,
    };
  },

  computed: {
    featuredImage() {
      return this.quotation.product.images[this.featuredImageIndex].original;
    },
  },

  methods: {
    formatPrice(price) {
      return priceFormatter(price);
    },
  },
};
</script>

<style></style>
