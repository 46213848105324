<template>
  <v-button-primary @click="onClick">
    <v-button-icon v-if="isLoading">
      <loader :radius="5" />
    </v-button-icon>
    Adauga in cos
  </v-button-primary>
</template>

<script>
import cartService from "../services/cartService";
import Loader from "./Loader.vue";
import VButtonIcon from "./VButtonIcon.vue";
import VButtonPrimary from "./VButtonPrimary.vue";
export default {
  components: { Loader, VButtonPrimary, VButtonIcon },

  props: {
    product: {
      type: Object,
    },
    extra: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    onClick() {
      this.isLoading = true;

      const payload = {
        items: [
          {
            product_id: this.product.id,
            quantity: 1,
            ...this.extra,
          },
        ],
      };

      cartService
        .create(payload)
        .then(() => {
          this.$emit("addToCart:item");
          this.isLoading = false;
        })
        .catch(() => (this.isLoading = false));
    },
  },
};
</script>

<style></style>
