import { readonly, ref } from 'vue';

export default (initialState) => {
    const loading = ref(initialState);

    function startLoading() {
        loading.value = true;
    };

    function endLoading() {
        loading.value = false;
    };

    return {
        loading: readonly(loading),
        startLoading,
        endLoading
    }
}