import { RFQS, RFQS_QUOTATIONS, RFQS_SHOW, RFQ_CONFIRMATIONS } from "../endpoints";
import api from "../api";

export default {
    index(query = null) {
        let url = new URL(RFQS);

        if (query)
            url.search = new URLSearchParams(query).toString();

        return api.get(url);
    },

    show(rfq = null) {
        if (rfq === null) return;

        const url = RFQS_SHOW.replace(":rfq", rfq);

        return api.get(url);
    },

    store(data = null) {
        if (data === null)
            return;
        return api.post(RFQS, data);
    },

    quotations(rfq, query) {
        if (rfq === null) return;

        let url = new URL(RFQS_QUOTATIONS.replace(":rfq", rfq));

        if (query !== null) {
            url.search = new URLSearchParams(query).toString();;
        }

        return api.get(url);
    },

    confirmation(query) {
        let url = new URL(RFQ_CONFIRMATIONS);

        if (query !== null) url.search = new URLSearchParams(query).toString();

        return api.get(url);
    }
}