<template>
  <app-layout>
    <v-container>
      <page-header>
        <page-title>
          Cerere
          <span class="text-yellow-600">#{{ rfq }} </span>
        </page-title>
      </page-header>
      <loader v-if="loading" />
      <rfqs-show-list v-else :quotations="quotations" />
    </v-container>
  </app-layout>
</template>

<script>
import VContainer from "../components/VContainer.vue";
import AppLayout from "../layout/AppLayout.vue";
import PageHeader from "../components/PageHeader.vue";
import PageTitle from "../components/PageTitle.vue";
import Loader from "../components/Loader.vue";
import RfqsShowList from "../components/RfqsShowList.vue";
import { ref } from "@vue/reactivity";
import useLoader from "../composables/useLoader";
import rfqsApi from "../api/resources/rfqsApi";
import { watch } from "@vue/runtime-core";
import { useRouter } from "vue-router";

export default {
  components: {
    VContainer,
    AppLayout,
    PageHeader,
    PageTitle,
    Loader,
    RfqsShowList,
  },

  props: {
    rfq: String,
  },

  setup(props) {
    const router = useRouter();
    const { loading, startLoading, endLoading } = useLoader(true);
    const quotations = ref([]);
    const retrievedRfq = ref({});

    rfqsApi
      .show(props.rfq)
      .then((response) => {
        retrievedRfq.value = response.data;
        endLoading();
      })
      .catch((error) => {
        endLoading();
        if (error.response.status === 410) router.push({ name: "notFound" });
      });

    watch(retrievedRfq, () => {
      startLoading();
      rfqsApi
        .quotations(props.rfq)
        .then((response) => {
          quotations.value = response.data;
          endLoading();
        })
        .catch(() => {
          endLoading();
        });
    });

    return {
      quotations,
      loading,
    };
  },
};
</script>