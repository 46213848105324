export const priceFormatter = (price) => {
    const formatter = new Intl.NumberFormat("ro-Ro", {
        style: "currency",
        currency: "Lei",
    });
    return formatter.format(parseFloat(price / 100).toFixed(2));
}

export const submitForm = (path, params, method = "post") => {
    const form = document.createElement("form");
    form.method = method;
    form.action = path;

    for (const key in params) {
        // if (params.hasOwnProperty(key)) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
            const hiddenField = document.createElement("input");
            hiddenField.type = "hidden";
            hiddenField.name = key;
            hiddenField.value = params[key];

            form.appendChild(hiddenField);
        }
    }

    document.body.appendChild(form);

    form.submit();
}