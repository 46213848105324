<template>
  <header class="pb-2 mb-4 px-4 md:px-0">
    <slot></slot>
  </header>
</template>

<script>
export default {};
</script>

<style></style>
