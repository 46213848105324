<template>
  <h1 class="font-medium text-gray-800 dark:text-white text-3xl">
    <slot></slot>
  </h1>
</template>

<script>
export default {};
</script>

<style></style>
